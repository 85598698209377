*{
  box-sizing: border-box;
  margin:0;
  padding: 0;
}

.App{
    background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    height: auto;
}





.app2{
  height: 100vh;
  margin: 0px;
  padding:0px;
}

.Contenido{
  padding: 0px;
  margin: 0px;
  height: 93vh;
 
}
.imagen{
  height: 92vh;

}


.letra{
  font-size: 24px;
  
  text-align: justify
}



