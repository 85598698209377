*{
    box-sizing:border-box;
    padding: 0px;
    margin: 0px;
}

.principal{
    color: white;
}
body{
    min-width: 320px;
    min-height: 100vh;
}

.contenedorImagen{
    height: 100vh;
    width: 100vw;
}

.mensaje{
    font-size: 22px;
    text-align: center;
}

.contenedor-imagen{
width: 320px;
margin:  0px auto;
text-align: center;
}

header .imagen{
    width: 300px;
    height: auto;
}

main {
    margin-top: 15px;
    text-align: center;
   
}
main .icono{
    font-size: 225px;
    margin-bottom: 20px;
}

.grupo{
width: 300px;
}

.contenedor-formulario{
    width: 300px;
    height: auto;
    margin: 0px auto;
}

main form{
    width: 250px;
    height: auto;
    margin: 0px auto;
    text-align: start;
}


main form label{
    margin-top: 10px;
}

.contenedor-contenedor{
    display: flex;
    width: 250px;
}

.contenedor-span1{
    background: #e9ecef;
    width: 30px;
}

.icono2{
    border: none;
    background: #e9ecef;
    text-align: center;
    border-radius: 3px;
    width: 30px;
    color: black;
}
main form input{
    width: 230px;
    font-size: 16px;
}


.contenedor-boton{
    width: 250px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 3px;
    text-align: center;
}

.boton{
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
    padding: 5px;
    font-size: 1rem;
    border-radius: 0.25rem;
    width: 220px;
}
footer{
    text-align: center;
}

footer a{
    padding-top: 0px;
    margin: 0px auto;
    font-size: 15px;
}

@media only screen and (min-width: 768px) {
    
    .principal{
        color: white;
    }
    .contenedorImagen{
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items:center
    }

  }

  @media only screen and (min-width: 1024px) {
    
    .principal{
        color: white;
    }
    .contenedorImagen{
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content:space-evenly;
        align-items:center;
        overflow: hidden;
    }
    header{
      
        width: 500px;
        height: auto;
     
    }

    
    .mensaje{

        font-size: 24px;
        text-align: center;
        
    }

    .contenedor-imagen{
        width: 480px;
        margin: 0px auto;
        text-align: center;

    }

    header .imagen{
        width: 450px;
        height: auto;
    }
    
    main{
        width: 500px;
        margin-left: 100px;
    }
    
    main h3{
        margin-right: 150px;
        
    }
    main .icono{
        font-size: 250px;
        margin-bottom: 20px;
        margin-right: 150px;
    }

    main form{
        width: 500px;
        height: auto;
        text-align: start;
    }

    .contenedor-contenedor{
        display: flex;
        width: 500px;
    }

    .contenedor-formulario{
        width: 500px;
        height: auto;
    }

    main form input{
        width: 400px; 
        height: 35px;
    }

    main form span{
        font-size: 20px;
        height: 35px;
    }

    .contenedor-boton{
        width: 550px;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
        margin-bottom: 3px;
        text-align: start;
    }
    
    .boton{
        color: #fff;
        background-color: #0d6efd;
        border-color: #0d6efd;
        padding: 5px;
        font-size: 1rem;
        border-radius: 0.25rem;
        width: 430px;
    }

    footer{
        text-align: center;
        margin-left:90px ;
    }

  }
